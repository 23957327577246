<template>
  <!-- 营销活动 -->
  <div>
    <div v-if="isShow">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            ><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>优惠券</el-breadcrumb-item>
        </el-breadcrumb>
        <el-button type="success" style="height: 36px" @click="reload"
          ><i style="font-size: 18px" class="el-icon-refresh-right"></i
        ></el-button>
      </div>
      <div style="display: flex; align-items: center; padding: 50px 20px">
        <div
          v-for="activity in activitys"
          :key="activity.id"
          @click="activityShow(activity.id)"
          style="
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            border: 1px solid #cccccc;
            border-radius: 20px;
            width: 200px;
            height: 270px;
            margin-left: 30px;
            cursor: pointer;
          "
        >
          <img
            style="width: 50px; height: 50px; margin-top: 30px"
            :src="activity.pic"
            alt=""
          />
          <p style="font-size: 36px; color: #333; margin: 10px 0">
            {{ activity.title }}
          </p>
          <p
            style="
              width: 158px;
              font-size: 14px;
              font-family: 'PingFang SC';
              color: #333;
            "
          >
            {{ activity.tip }}
          </p>
        </div>
      </div>
    </div>
    <transition
      enter-active-class="animate__fadeInUpBig"
      leave-active-class="animate__fadeOutUpBig"
    >
      <div>
        <div v-if="!isShow">
          <active-group
            :breadcrumb="breadcrumb[label]"
            @closeClick="closeClick"
          ></active-group>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    activeGroup: () => import("./childrens/discountCouponGroup.vue"),
  },
  data() {
    return {
      isShow: true,
      breadcrumb: ["现金券", "折扣券", "商品券", "卡券包"],
      label: 0,
      activitys: [
        {
          id: 1,
          pic: require("@/assets/mianAssets/label9_assets/现金券.png"),
          title: "现金券",
          tip: "例:现金抵用券。存用户卡包，消费时自动抵扣。",
        },
        {
          id: 2,
          pic: require("@/assets/mianAssets/label9_assets/折扣券.png"),
          title: "折扣券",
          tip: "例:商品打折券。存用户卡包，付款时自动打折。",
        },
        {
          id: 3,
          pic: require("@/assets/mianAssets/label9_assets/商品券.png"),
          title: "商品券",
          tip: "例:把商品生成券的形式，然后分享赠送给朋友或客户。领券后生成运单。",
        },
        {
          id: 4,
          pic: require("@/assets/mianAssets/label9_assets/卡券包.png"),
          title: "卡券包",
          tip: "例:将多种或多个礼品和优惠券生成一个礼品包。",
        },
      ],
    };
  },
  mounted() {},
  computed: {},
  methods: {
    reload() {
      location.reload();
    },
    activityShow(i) {
      this.isShow = !this.isShow;
      this.label = --i;
    },
    closeClick() {
      this.isShow = true;
    },
  },
};
</script>
<style scoped>
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
